.multiChoiceQuestionInput .multiChoiceOptions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 5px;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox {
  border: 1px solid #999;
  margin: 0 0 20px 20px;
  width: calc(33.33% - 20px);
  border-radius: 6px;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox:nth-of-type(1) {
  margin-left: 0;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel {
  cursor: pointer;
  position: relative;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceText {
  background-color: #fff;
  display: block;
  padding: 10px;
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  font-size: 13px;
  border-radius: 6px;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceInput {
  position: absolute;
  z-index: -1;
  margin: 10px;
  height: 50px;
  width: calc(100% - 20px);
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceInput:checked + .choiceText {
  background-color: #e25714;
  color: #fff;
  outline: 2px solid #0000b4;
  border: 1px solid transparent;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceInput:focus + .choiceText {
  outline: 2px solid #0000b4;
  border: 1px solid transparent;
}
.multiChoiceQuestionInput .multiChoiceOptions .validationSymbol {
  top: 25px;
}
.multiChoiceQuestionInput .inputErrors {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
  margin-bottom: 20px;
  color: #db0038;
}
input + .multiChoiceQuestionInput .inputErrors {
  margin-top: -15px;
}
/* 
    note that everything below is for the hover and click animations
    these could be async if we want to set it up for the profile entrypoint 
*/
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox:hover {
  -webkit-animation-name: hvr-buzz-out;
          animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected {
  content: "";
  position: absolute;
  margin: auto;
  height: 8px;
  width: 2px;
  background: #e25714;
  opacity: 0;
  z-index: 2;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected.choiceSelected1 {
  top: -20px;
  left: 0;
  right: 0;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected.choiceSelected2 {
  bottom: -20px;
  left: 0;
  right: 0;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected.choiceSelected3 {
  top: 0;
  bottom: 0;
  left: -10px;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected.choiceSelected4 {
  top: 0;
  bottom: 0;
  right: -10px;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected.choiceSelected5 {
  top: -20px;
  left: -70%;
  right: 0;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected.choiceSelected6 {
  top: -20px;
  left: 0;
  right: -70%;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected.choiceSelected7 {
  bottom: -20px;
  right: 0;
  left: -70%;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceSelectedDecoration .choiceSelected.choiceSelected8 {
  bottom: -20px;
  left: 0;
  right: -70%;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected1 {
  -webkit-animation: 'anim1' 0.4s ease-out 1;
          animation: 'anim1' 0.4s ease-out 1;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected2 {
  -webkit-animation: 'anim2' 0.4s ease-out 1;
          animation: 'anim2' 0.4s ease-out 1;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected3 {
  -webkit-animation: 'anim3' 0.4s ease-out 1;
          animation: 'anim3' 0.4s ease-out 1;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected4 {
  -webkit-animation: 'anim4' 0.4s ease-out 1;
          animation: 'anim4' 0.4s ease-out 1;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected5 {
  -webkit-animation: 'anim5' 0.4s ease-out 1;
          animation: 'anim5' 0.4s ease-out 1;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected6 {
  -webkit-animation: 'anim6' 0.4s ease-out 1;
          animation: 'anim6' 0.4s ease-out 1;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected7 {
  -webkit-animation: 'anim7' 0.4s ease-out 1;
          animation: 'anim7' 0.4s ease-out 1;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceInput:checked + .choiceText + .choiceSelectedDecoration .choiceSelected8 {
  -webkit-animation: 'anim8' 0.4s ease-out 1;
          animation: 'anim8' 0.4s ease-out 1;
}
@-webkit-keyframes hvr-buzz-out {
  12.5% {
    -webkit-transform: translateX(2px) rotate(2deg);
            transform: translateX(2px) rotate(2deg);
  }
  25% {
    -webkit-transform: translateX(-2px) rotate(-2deg);
            transform: translateX(-2px) rotate(-2deg);
  }
  37.5% {
    -webkit-transform: translateX(2px) rotate(2deg);
            transform: translateX(2px) rotate(2deg);
  }
  50% {
    -webkit-transform: translateX(-2px) rotate(-2deg);
            transform: translateX(-2px) rotate(-2deg);
  }
  62.5% {
    -webkit-transform: translateX(1px) rotate(1deg);
            transform: translateX(1px) rotate(1deg);
  }
  75% {
    -webkit-transform: translateX(-1px) rotate(-1deg);
            transform: translateX(-1px) rotate(-1deg);
  }
  87.5% {
    -webkit-transform: translateX(1px) rotate(1deg);
            transform: translateX(1px) rotate(1deg);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(-1deg);
            transform: translateX(-1px) rotate(-1deg);
  }
}
@keyframes hvr-buzz-out {
  12.5% {
    -webkit-transform: translateX(2px) rotate(2deg);
            transform: translateX(2px) rotate(2deg);
  }
  25% {
    -webkit-transform: translateX(-2px) rotate(-2deg);
            transform: translateX(-2px) rotate(-2deg);
  }
  37.5% {
    -webkit-transform: translateX(2px) rotate(2deg);
            transform: translateX(2px) rotate(2deg);
  }
  50% {
    -webkit-transform: translateX(-2px) rotate(-2deg);
            transform: translateX(-2px) rotate(-2deg);
  }
  62.5% {
    -webkit-transform: translateX(1px) rotate(1deg);
            transform: translateX(1px) rotate(1deg);
  }
  75% {
    -webkit-transform: translateX(-1px) rotate(-1deg);
            transform: translateX(-1px) rotate(-1deg);
  }
  87.5% {
    -webkit-transform: translateX(1px) rotate(1deg);
            transform: translateX(1px) rotate(1deg);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(-1deg);
            transform: translateX(-1px) rotate(-1deg);
  }
}
@-webkit-keyframes anim1 {
  0% {
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-10px) scaleY(1.4);
            transform: translateY(-10px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px) scaleY(0.5);
            transform: translateY(-20px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim1 {
  0% {
    -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(-10px) scaleY(1.4);
            transform: translateY(-10px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px) scaleY(0.5);
            transform: translateY(-20px) scaleY(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes anim2 {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(10px) scaleY(1.4);
            transform: translateY(10px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px) scaleY(0.5);
            transform: translateY(20px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim2 {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(10px) scaleY(1.4);
            transform: translateY(10px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px) scaleY(0.5);
            transform: translateY(20px) scaleY(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes anim3 {
  0% {
    -webkit-transform: rotate(90deg) scaleX(0.5);
            transform: rotate(90deg) scaleX(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(90deg) translateY(10px) scaleX(1.4);
            transform: rotate(90deg) translateY(10px) scaleX(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(90deg) translateY(20px) scaleX(0.5);
            transform: rotate(90deg) translateY(20px) scaleX(0.5);
    opacity: 0;
  }
}
@keyframes anim3 {
  0% {
    -webkit-transform: rotate(90deg) scaleX(0.5);
            transform: rotate(90deg) scaleX(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(90deg) translateY(10px) scaleX(1.4);
            transform: rotate(90deg) translateY(10px) scaleX(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(90deg) translateY(20px) scaleX(0.5);
            transform: rotate(90deg) translateY(20px) scaleX(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes anim4 {
  0% {
    -webkit-transform: rotate(90deg) scaleX(0.5);
            transform: rotate(90deg) scaleX(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(90deg) translateY(-10px) scaleX(1.4);
            transform: rotate(90deg) translateY(-10px) scaleX(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(90deg) translateY(-20px) scaleX(0.5);
            transform: rotate(90deg) translateY(-20px) scaleX(0.5);
    opacity: 0;
  }
}
@keyframes anim4 {
  0% {
    -webkit-transform: rotate(90deg) scaleX(0.5);
            transform: rotate(90deg) scaleX(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(90deg) translateY(-10px) scaleX(1.4);
            transform: rotate(90deg) translateY(-10px) scaleX(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(90deg) translateY(-20px) scaleX(0.5);
            transform: rotate(90deg) translateY(-20px) scaleX(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes anim5 {
  0% {
    -webkit-transform: rotate(-30deg) scaleY(0.5);
            transform: rotate(-30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(-30deg) translateY(-8px) scaleY(1.4);
            transform: rotate(-30deg) translateY(-8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-30deg) translateY(-16px) scaleY(0.5);
            transform: rotate(-30deg) translateY(-16px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim5 {
  0% {
    -webkit-transform: rotate(-30deg) scaleY(0.5);
            transform: rotate(-30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(-30deg) translateY(-8px) scaleY(1.4);
            transform: rotate(-30deg) translateY(-8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-30deg) translateY(-16px) scaleY(0.5);
            transform: rotate(-30deg) translateY(-16px) scaleY(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes anim6 {
  0% {
    -webkit-transform: rotate(30deg) scaleY(0.5);
            transform: rotate(30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(30deg) translateY(-8px) scaleY(1.4);
            transform: rotate(30deg) translateY(-8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(30deg) translateY(-16px) scaleY(0.5);
            transform: rotate(30deg) translateY(-16px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim6 {
  0% {
    -webkit-transform: rotate(30deg) scaleY(0.5);
            transform: rotate(30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(30deg) translateY(-8px) scaleY(1.4);
            transform: rotate(30deg) translateY(-8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(30deg) translateY(-16px) scaleY(0.5);
            transform: rotate(30deg) translateY(-16px) scaleY(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes anim7 {
  0% {
    -webkit-transform: rotate(30deg) scaleY(0.5);
            transform: rotate(30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(30deg) translateY(8px) scaleY(1.4);
            transform: rotate(30deg) translateY(8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(30deg) translateY(16px) scaleY(0.5);
            transform: rotate(30deg) translateY(16px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim7 {
  0% {
    -webkit-transform: rotate(30deg) scaleY(0.5);
            transform: rotate(30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(30deg) translateY(8px) scaleY(1.4);
            transform: rotate(30deg) translateY(8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(30deg) translateY(16px) scaleY(0.5);
            transform: rotate(30deg) translateY(16px) scaleY(0.5);
    opacity: 0;
  }
}
@-webkit-keyframes anim8 {
  0% {
    -webkit-transform: rotate(-30deg) scaleY(0.5);
            transform: rotate(-30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(-30deg) translateY(8px) scaleY(1.4);
            transform: rotate(-30deg) translateY(8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-30deg) translateY(16px) scaleY(0.5);
            transform: rotate(-30deg) translateY(16px) scaleY(0.5);
    opacity: 0;
  }
}
@keyframes anim8 {
  0% {
    -webkit-transform: rotate(-30deg) scaleY(0.5);
            transform: rotate(-30deg) scaleY(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(-30deg) translateY(8px) scaleY(1.4);
            transform: rotate(-30deg) translateY(8px) scaleY(1.4);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-30deg) translateY(16px) scaleY(0.5);
            transform: rotate(-30deg) translateY(16px) scaleY(0.5);
    opacity: 0;
  }
}
