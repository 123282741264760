.requestPasswordResetPage .requestPasswordResetForm {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
}
.requestPasswordResetPage .requestPasswordResetForm .copySection,
.requestPasswordResetPage .requestPasswordResetForm .successCopySection {
  width: 100%;
}
.requestPasswordResetPage .requestPasswordResetForm .requestPasswordResetFormContainer {
  width: 100%;
}
.requestPasswordResetPage .requestPasswordResetForm .requestPasswordResetFormContainer .requestPasswordResetEmailSection .requestPasswordResetLabel {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.requestPasswordResetPage .requestPasswordResetForm .requestPasswordResetFormContainer .requestPasswordResetEmailSection .inputError {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
  padding: 6px;
}
.requestPasswordResetPage .requestPasswordResetForm .requestPasswordResetFormContainer .requestPasswordResetEmailSection .inputError:focus {
  outline-width: 0;
}
.requestPasswordResetPage .requestPasswordResetForm .requestPasswordResetFormContainer .requestPasswordResetSubmitSection {
  margin-top: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
