@media screen and (min-width:1000px) {
.survey.hideHeaderFooter .surveyContainer .modal .modalHeader .modalClose {
  top: 10px;
  right: 10px;
}
.survey.hideHeaderFooter .surveyContainer .modal .modalHeader .modalClose .modalCloseButton:after {
  color: white;
  background-color: unset;
  border-radius: unset;
}
.survey .surveyContainer .modal {
  max-width: 1000px;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox {
  width: unset;
}

}
