.surveyNavigation {
  text-align: center;
}
.surveyNavigation .errorMessage p {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  color: #E60000;
  font-weight: bold;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.surveyNavigation .errorMessage p:before {
  margin-right: 10px;
  font-weight: normal;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-family: 'Icon Fonts';
  content: "\e934";
  color: #E60000;
}
.surveyNavigation .registrationLink {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #e25714;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  vertical-align: middle;
}
.surveyNavigation .registrationLink:hover {
  text-decoration: none;
}
.surveyNavigation .registrationLink a {
  color: #fff;
}
.surveyNavigation .previousButton {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #e25714;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  vertical-align: middle;
  color: #e25714;
  background: #fff;
  border: solid 2px #e25714;
  margin-right: 10px;
  padding: 8px 10px;
}
.surveyNavigation .previousButton:hover {
  text-decoration: none;
}
.surveyNavigation .previousButton a {
  color: #fff;
}
.surveyNavigation .completeButton,
.surveyNavigation .registrationLink {
  padding: 10px;
}
.surveyNavigation .nextButton {
  padding: 10px 25px;
}
.surveyNavigation .logInContainer {
  margin-top: 20px;
}
.surveyNavigation .logInContainer .logInLink {
  font-weight: bold;
}
