.surveyRegistration .registrationHeading {
  padding-bottom: 10px;
}
.surveyRegistration .registrationHeading .logInContainer {
  text-align: center;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
}
.surveyRegistration .registrationHeading .registrationHeaderText {
  display: none;
}
.surveyRegistration .socialLogin {
  width: 100%;
  margin-top: 10px;
}
.surveyRegistration .socialLogin ul.socialConnectItems {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  gap: 10px;
}
.surveyRegistration .socialLogin ul.socialConnectItems li.socialConnectItem {
  -ms-flex: 1;
      flex: 1;
  margin: 10px;
}
.surveyRegistration .socialLogin ul.socialConnectItems li.socialConnectItem .socialConnectButton {
  width: 100%;
}
.surveyRegistration .socialLogin ul.socialConnectItems li.socialConnectItem .socialConnectButton .socialConnectButtonText {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.surveyRegistration .socialLogin ul.socialConnectItems li.socialConnectItem .socialConnectButton .socialProviderImage {
  height: 25px;
  width: 25px;
}
.surveyRegistration .registrationForm {
  padding: 0 40px;
}
.surveyRegistration input,
.surveyRegistration select {
  border-radius: 6px;
  padding: 9px;
  border: solid 1px #888;
}
.surveyRegistration .requiredText {
  width: 100%;
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
  margin: 10px 0;
}
.surveyRegistration .registrationLabel {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.surveyRegistration .registrationHelpText {
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #666666;
  display: block;
  margin-bottom: 5px;
}
.surveyRegistration .inputSkeleton {
  background-color: #f5f5f6;
}
.surveyRegistration .inputSkeleton.passwordSkeleton {
  margin-bottom: 150px;
}
.surveyRegistration .registrationSocial {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 30px;
  font-weight: bold;
}
.surveyRegistration .registrationSocial img.providerImage {
  width: 32px;
  height: 32px;
  margin-right: 20px;
}
.surveyRegistration .hhCompSkeleton {
  display: block;
  background-color: #f5f5f6;
  width: 100%;
  height: 90px;
  margin-bottom: 20px;
  border-radius: 6px;
}
.surveyRegistration .registrationNewsletterSection {
  background-color: #f5f5f6;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
}
.surveyRegistration .registrationNewsletterSection .newsletterHeading {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  margin-bottom: 10px;
}
.surveyRegistration .registrationNewsletterSection .newsletterHeading a {
  display: inline-block;
}
.surveyRegistration .registrationNewsletterSection .newsletterLabel {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.surveyRegistration .registrationNewsletterSection .newsletterPreview {
  color: #333333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #666666;
  margin-top: 5px;
}
.surveyRegistration .registrationNewsletterSection .newsletterLabel,
.surveyRegistration .registrationNewsletterSection .newsletterPreview {
  display: -ms-flexbox;
  display: flex;
}
.surveyRegistration .registrationNewsletterSection .newsletterLabel .newsletterCheckboxSpacing,
.surveyRegistration .registrationNewsletterSection .newsletterPreview .newsletterCheckboxSpacing {
  width: 25px;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}
.surveyRegistration .registrationSubmitSection {
  text-align: center;
}
.surveyRegistration .registrationSubmitSection .registrationLegalText {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 20px;
  line-height: 1.25rem;
}
.surveyRegistration .recaptcha > div {
  margin: 20px auto 0;
}
.surveyRegistration .errorSection {
  margin-top: 20px;
  background-color: #FCE5E5;
  padding: 20px;
  border-radius: 6px;
}
.surveyRegistration .errorSection.socialErrorSection {
  margin-bottom: 20px;
}
.surveyRegistration .errorSection .errorHeading {
  font-weight: bold;
}
.surveyRegistration .errorSection .errorListItem {
  margin-top: 5px;
}
.surveyRegistration .inputErrors {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
  margin-bottom: 20px;
  color: #db0038;
  margin-bottom: 10px;
  margin-top: 3px;
}
input + .surveyRegistration .inputErrors {
  margin-top: -15px;
}
.surveyRegistration .registrationBlockedHeading {
  color: #000;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 20px;
}
.surveyRegistration .registrationBlockedHeading a {
  display: inline-block;
}
.surveyRegistration .fieldInputParent {
  display: -ms-flexbox;
  display: flex;
}
.surveyRegistration .linkSocialAccount .linkSocialAccountHeaderText {
  text-align: center;
  font-weight: bold;
}
.surveyRegistration .linkSocialAccount .linkSocialAccountText {
  -ms-flex-pack: center;
      justify-content: center;
  font-weight: 100;
}
.surveyRegistration .linkSocialAccount .linkSocialAccountFormContainer {
  width: auto;
}
.surveyRegistration .registrationBlocked {
  text-align: center;
}
