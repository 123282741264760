@media screen and (min-width:1000px) {
.surveyRegistration .registrationForm {
  margin: 0 50px;
  position: relative;
}
.surveyRegistration input,
.surveyRegistration .passwordButtonContainer {
  max-width: 300px;
}
.surveyRegistration .inputSkeleton.passwordSkeleton {
  margin-bottom: 50px;
}
.surveyRegistration .smallInput {
  width: 180px;
}
.surveyRegistration .registrationSubmitSection .registrationLegalText {
  margin-bottom: 40px;
}
.surveyRegistration .fieldInputParent {
  -ms-flex-flow: nowrap;
      flex-flow: nowrap;
}
.surveyRegistration .recaptcha > div {
  position: absolute;
  bottom: 0;
  right: 0;
}
.surveyRegistration .socialLogin ul.socialConnectItems {
  -ms-flex-pack: center;
      justify-content: center;
}
.surveyRegistration .socialLogin ul.socialConnectItems li.socialConnectItem {
  -ms-flex: none;
      flex: none;
}

}
