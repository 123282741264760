@media screen and (min-width:767px) {
.progressBar ol {
  margin-bottom: 40px;
}
.progressBar ol li .questionStepButton {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0 auto;
}
.progressBar ol li .questionStepButton:before {
  width: 20px;
  height: 20px;
  margin: 10px auto;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.25rem;
}
.progressBar ol li.completed .questionStepButton:before {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.progressBar .line {
  top: 36px;
}

}
