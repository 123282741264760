.emailInput {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
}
.emailInput .emailField {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.emailInput .emailField .emailFieldInput:focus {
  outline: 2px solid #0000b4;
  border: 1px solid transparent;
}
.emailInput .emailField .emailFieldInput:active {
  outline: none;
  border: 1px solid #0000b4;
  box-shadow: 0 0 6px 0 rgba(0, 0, 180, 0.25);
  padding: 9px;
}
.emailInput .inputEmailErrorMessage {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
  margin-bottom: 20px;
  color: #db0038;
  margin-bottom: 10px;
  margin-top: 3px;
}
input + .emailInput .inputEmailErrorMessage {
  margin-top: -15px;
}
.emailInput .emailSubError {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
  margin-bottom: 20px;
  color: #000;
  margin-bottom: 10px;
}
input + .emailInput .emailSubError {
  margin-top: -15px;
}
