@media screen and (min-width:1000px) {
.requestPasswordResetPage .requestPasswordResetForm .copySection,
.requestPasswordResetPage .requestPasswordResetForm .successCopySection {
  width: 75%;
  max-width: 450px;
}
.requestPasswordResetPage .requestPasswordResetForm .requestPasswordResetFormContainer {
  width: 50%;
}

}
