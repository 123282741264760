@media screen and (min-width:1000px) {
.resetPasswordPage .resetPasswordForm {
  padding-right: 0;
}
.resetPasswordPage input,
.resetPasswordPage .passwordButtonContainer {
  max-width: 300px;
}

}
