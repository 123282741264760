@media screen and (min-width:767px) {
.survey .surveyContainer .modal {
  width: calc(100% - 80px);
  height: auto;
  max-height: calc(100% - 80px);
}
.survey .surveyContainer .modal .modalHeader .surveyTitle {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 2rem;
  line-height: 2.375rem;
}
.survey .surveyContainer .currentStepContent .multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceInput:hover + .choiceText {
  border-color: #e25714;
  background-color: #e25714;
  color: #fff;
}
.surveyPrivacyContainer {
  padding: 50px;
}
.surveyPrivacyContainer .privacyMessage {
  background: #fff;
  max-height: 100%;
  max-width: 100%;
  padding: 80px;
}
.surveyPrivacyContainer .privacyMessage h3 {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
}
.surveyPrivacyContainer .privacyMessage h3 a {
  display: inline-block;
}
.surveyPrivacyContainer .privacyMessage p {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-weight: bold;
}

}
