@media screen and (min-width:767px) {
.registrationPage .registrationSubmitSection {
  text-align: left;
  margin-top: 45px;
}
.registrationPage .recaptcha > div {
  margin-left: 0;
}

}
