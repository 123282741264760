.progressBar {
  position: relative;
}
.progressBar ol {
  display: -ms-flexbox;
  display: flex;
  counter-reset: item;
  margin-bottom: 20px;
  -ms-flex-direction: row;
      flex-direction: row;
}
.progressBar ol li {
  counter-increment: item;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0;
      flex: 1 0 0;
  -ms-flex-direction: column;
      flex-direction: column;
}
.progressBar ol li .questionStepButton:before {
  display: block;
  -ms-flex: none;
      flex: none;
  width: 20px;
  height: 20px;
  margin: 5px auto;
  padding: 5px;
  content: counter(item);
  text-align: center;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: bold;
  color: black;
  background-color: white;
  border: 2px solid black;
  border-radius: 100%;
  z-index: 1;
}
.progressBar ol li {
  -ms-flex-direction: column;
      flex-direction: column;
}
.progressBar ol li .questionStepButton {
  background: transparent;
  margin: 7px auto 0;
  padding: 10px 0;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.625rem;
  line-height: 0.8125rem;
  color: black;
}
.progressBar ol li .questionStepButton:disabled {
  opacity: unset;
}
.progressBar ol li .questionStepButton:hover:not(:disabled) .questionStepName {
  text-decoration: underline;
}
.progressBar ol li .questionStepButton:before {
  width: 12px;
  height: 12px;
  margin: 0 auto;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.625rem;
  line-height: 0.8125rem;
  margin-bottom: 6px;
}
.progressBar ol li.completed .questionStepButton:before {
  font-family: 'Icon Fonts';
  content: "\e925";
  color: #2F8118;
  border: 2px solid #2F8118;
  font-weight: 500;
  font-size: 12px;
  background-color: white;
}
.progressBar .line {
  border-top: 2px solid grey;
  position: absolute;
  top: 28px;
  z-index: -1;
}
