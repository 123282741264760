@media screen and (min-width:1000px) {
.registrationPage .registrationForm {
  padding-right: 0;
  position: relative;
}
.registrationPage input,
.registrationPage .passwordButtonContainer {
  max-width: 300px;
}
.registrationPage .inputSkeleton.passwordSkeleton {
  margin-bottom: 50px;
}
.registrationPage .smallInput {
  width: 180px;
}
.registrationPage .registrationSubmitSection .registrationLegalText {
  margin-bottom: 40px;
}
.registrationPage .recaptcha > div {
  position: absolute;
  bottom: 0;
  right: 0;
}

}
