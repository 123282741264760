@media screen and (min-width:1000px) {
.pageContainer {
  max-width: none;
  padding: 0;
  background-color: #f5f5f6;
}
.pageContainer .profilePage .twoColumnProfileSection {
  padding: 40px;
}
.pageContainer .profilePage .twoColumnProfileSection .valuePropColumn {
  display: block;
}
.pageContainer .profilePage .twoColumnProfileSection .contentColumn {
  padding: 40px;
  background-color: white;
  max-width: 600px;
}
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .loginHeading,
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .registrationHeading {
  padding: 0;
}
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .signUpContainer,
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .logInContainer {
  margin: 0 10px;
}
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .signUpContainer .containerText,
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .logInContainer .containerText {
  display: block;
}
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .socialLogin {
  width: auto;
  margin-top: 10px;
}
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .socialLogin ul.socialConnectItems li.socialConnectItem {
  -ms-flex: auto;
      flex: auto;
}
.pageContainer .profilePage .twoColumnProfileSection .contentColumn .socialLogin ul.socialConnectItems li.socialConnectItem:first-child {
  margin-left: 10px;
}

}
