@media screen and (min-width:1000px) {
.thankYouPage .contentColumn {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 100px 150px !important;
}
.thankYouPage .thankYouContainer .thankYouHeaderText {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 2.25rem;
  line-height: 2.75rem;
  margin-bottom: 45px;
}
.thankYouPage .thankYouContainer .thankYouDescription {
  margin-bottom: 45px;
}
.thankYouPage .thankYouContainer .thankYouDescription img {
  max-height: 300px;
  margin-bottom: 10px;
}

}
