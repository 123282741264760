.socialConnect .socialConnectButton {
  border: 1px solid #ccc;
  background: transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 6px;
  margin: auto;
  border-radius: 8px;
  text-transform: none;
  font-weight: normal;
}
.socialConnect .socialConnectButton .socialProviderImage {
  height: 32px;
  width: 32px;
}
.socialConnect .socialConnectButton .socialConnectButtonText {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  margin: auto 10px auto 10px;
}
.socialConnect .socialConnectButton:focus,
.socialConnect .socialConnectButton:hover {
  outline: 2px solid #0000b4;
  border: 1px solid transparent;
}
.socialConnect .socialConnectButton:active {
  outline: none;
  border: 1px solid #0000b4;
  box-shadow: 0 0 6px 0 rgba(0, 0, 180, 0.25);
}
.socialConnectItemSkeleton {
  height: 45px;
  border-radius: 8px;
  background-color: #f5f5f6;
}
