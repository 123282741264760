@media screen and (min-width:1000px) {
.socialConnect .socialConnectButton {
  padding: 8px;
}
.socialConnect .socialConnectButton .socialConnectButtonText {
  width: 60px;
}
.socialConnectItemSkeleton {
  width: 130px;
}

}
