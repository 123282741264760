@media screen and (min-width:1000px) {
.passwordInput {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.passwordInput .passwordField {
  margin-bottom: 20px;
}
.passwordInput #passwordCriteriaBox {
  margin: 0 0 0 20px;
}

}
