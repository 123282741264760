.validInput,
.invalidInput {
  position: relative;
}
.validInput .validationSymbol,
.invalidInput .validationSymbol {
  top: 8px;
  left: -28px;
  z-index: 2;
  position: absolute;
  font-family: 'Icon Fonts';
  -webkit-transform: scale(1.6);
          transform: scale(1.6);
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}
.validInput .validationSymbol:before {
  content: "\e933";
  color: #2F8118;
}
.invalidInput .validationSymbol:before {
  content: "\e934";
  color: #E60000;
}
.validInput .emailFieldInput,
.validInput .passwordFieldInput,
.validInput .confirmPasswordFieldInput,
.validInput .zipCodeFieldInput,
.validInput .languagePreferenceFieldInput,
.birthdateInput .validInput .birthdateInputContainer .dateInputBox {
  background-color: rgba(47, 129, 24, 0.1);
}
.invalidInput .emailFieldInput,
.invalidInput .passwordFieldInput,
.invalidInput .confirmPasswordFieldInput,
.invalidInput .zipCodeFieldInput,
.invalidInput .languagePreferenceFieldInput,
.birthdateInput .invalidInput .birthdateInputContainer .dateInputBox {
  border: 2px solid #E60000;
  margin-bottom: 0;
}
.invalidInput .emailFieldInput,
.invalidInput .passwordFieldInput,
.invalidInput .confirmPasswordFieldInput,
.invalidInput .zipCodeFieldInput,
.invalidInput .languagePreferenceFieldInput {
  padding: 8px;
}
.birthdateInput .invalidInput .birthdateInputContainer .dateInputBox {
  padding: 0;
}
