@media screen and (min-width:1000px) {
.multiChoiceQuestionInput .multiChoiceOptions {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox {
  width: calc(25% - 20px);
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel {
  cursor: pointer;
  position: relative;
}
.multiChoiceQuestionInput .multiChoiceOptions .choiceBox .choiceLabel .choiceText {
  font-size: 15px;
}

}
